<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="appList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            label="方案配图"
            align="center">
          <template slot-scope="scope">
            <img class="table-img" :src="scope.row.img">
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="label"
            label="方案描述">
        </el-table-column>
        <el-table-column
            align="center"
            prop="remark"
            label="典型气体">
        </el-table-column>
        <el-table-column
            align="center"
            prop="weight"
            label="权重">
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addHonor">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="danger" @click="delApp(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="图片">
         <img-upload :url="addForm.url" @change="urlChange"></img-upload>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="text" v-model="addForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="典型气体">
          <el-input type="text" v-model="addForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="addForm.weight"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="addApp">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addApplication, getApplication, delApplication} from "@/plugins/api/app";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'Application',
  components: {ImgUpload},
  data() {
    return {
      appList: [

      ],
      previewInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      addForm: {
        url: '',
        weight: '',
        desc: '',
        remark: '',
      },
      fileList: []
    }
  },
  methods: {
    preview(row) {
      this.previewInfo = row;
      this.dialogVisible = true;
    },
    urlChange(url) {
      this.addForm.url = url;
    },
    addHonor() {
      this.addDialogVisible = true
    },
    async getApp() {
      this.appList = await getApplication();
    },
    async addApp() {
      await addApplication({
        img: this.addForm.url,
        label: this.addForm.desc,
        remark: this.addForm.remark,
        weight: this.addForm.weight,
      })
      this.getApp();
      this.addDialogVisible = false;
      this.addForm = {
        url: '',
        weight: '',
        desc: '',
        remark: '',
      };
    },
    async delApp(id) {
      await delApplication({id})
      this.getApp()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
  created() {
    this.getApp();
  }
}
</script>

<style scoped>
.table-img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}
.preview-img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
