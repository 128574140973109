export function getApplication(opt) {
    return axios.post('/application/item/get/', opt);
}

export function addApplication(opt) {
    return axios.post('/application/item/insert/', opt);
}

export function delApplication(opt) {
    return axios.post('/application/item/delete/', opt);
}
